<template>
  <div>
    <a-card :title="$t('计费规则')">
      <a-row gutter="16">
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" :placeholder="$t('编号, 名称, 备注')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
        </a-col>

        <a-col :span="24" :md="6" :xl="8" style="margin-bottom: 12px">
          <a-button-group>
            <a-button icon="file-excel" @click="downloadTemplate">{{ $t("模板下载") }}</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
              <a-button icon="upload">{{ $t("导入") }}</a-button>
            </a-upload>
            <a-button icon="download" @click="exportExcel">{{ $t("导出") }}</a-button>
          </a-button-group>
        </a-col>
      </a-row>

      <div>
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :data-source="items"
          :pagination="pagination"
          :loading="loading"
          @change="onChangeTable"
        >
          <div slot="is_active" slot-scope="value">
            <a-tag :color="value ? 'green' : 'red'">{{ value ? $t("激活") : $t("冻结") }}</a-tag>
          </div>
          <div slot="action" slot-scope="value, item, index">
            <a-button-group size="small">
              <a-button icon="edit" @click="openFormModal(item)">{{ $t("编辑") }}</a-button>
              <a-button icon="profile" type="primary" @click="viewChargeDetail(item)">
                {{ $t("单品规则") }}
              </a-button>
            </a-button-group>
          </div>
        </a-table>
      </div>
    </a-card>

    <form-modal v-model="visible" :form="targetItem" @update="list" />
    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />{{ $t("正在导入中, 请等待...") }}</div>
    </a-modal>
  </div>
</template>

<script>
import { chargeClientList } from "@/api/charge";
import { columns } from "./columns";
import { chargeRuleExport, chargeRuleTemplate, chargeRuleImport } from "@/api/charge";
import { exportExcel } from "@/utils/excel";

export default {
  components: {
    FormModal: () => import("./FormModal"),
  },
  data() {
    return {
      columns,
      pagination: { current: 1, total: 0, pageSize: 16, showTotal: (total) => `${this.$t("共")} ${total} ${this.$t("条")}` },
      searchForm: { page: 1, page_size: 16 },
      loading: false,
      visible: false,
      items: [],
      targetItem: {},
      importLoading: false,
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initData() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.list();
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    list() {
      this.loading = true;
      chargeClientList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openFormModal(item) {
      this.targetItem = {
        charge_unit: undefined,
        stock_in_transport_unit_price: undefined,
        stock_in_handling_unit_price: undefined,
        stock_out_transport_unit_price: undefined,
        stock_out_handling_unit_price: undefined,
        storage_unit_price: undefined,
        ...item,
      };
      this.visible = true;
    },
    viewChargeDetail(item) {
      this.$router.push({ path: "/charge/rule_detail", query: { id: item.id } });
    },
    exportExcel() {
      chargeRuleExport(this.searchForm)
        .then((resp) => {
          exportExcel(resp, this.$t("计费规则列表"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    downloadTemplate() {
      chargeRuleTemplate()
        .then((resp) => {
          exportExcel(resp, this.$t("计费规则导入模板"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    importExcel(item) {
      let data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      setTimeout(() => {
        chargeRuleImport(data)
          .then(() => {
            this.$message.success(this.$t("导入成功"));
            this.list();
          })
          .catch((err) => {
            alert(this.$t("导入错误:") + "\n" + err.response.data.join("\n"));
          })
          .finally(() => {
            this.importLoading = false;
          });
      }, 1000);
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
